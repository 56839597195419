import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left">
          <div className="mb-4 md:mb-0">
            <img src="/assets/RPSLogo2.png" alt="Reliance Power Supply Logo" className="w-32 h-20 mb-2 mx-auto md:mx-0" />
            <h1 className="text-2xl font-bold">Reliance Power Supply</h1>
            <p className="text-base">Reliable Transformers and Generators for Commercial Use</p>
          </div>
          <div className="flex flex-col items-center mb-4 md:mb-0">
            <p className="text-base mb-2">Contact Us</p>
            <p className="text-base">Email: <a href="mailto:info@reliancepowersupply.com" className="text-blue-400">info@reliancepowersupply.com</a></p>
            <p className="text-base">Phone: <a href="tel:+1234567890" className="text-blue-400">(631) 306-4602</a></p>
          </div>
          <div className="flex space-x-6 justify-center md:justify-start">
            <a href="https://facebook.com" className="text-white text-2xl"><FaFacebook /></a>
            <a href="https://twitter.com" className="text-white text-2xl"><FaTwitter /></a>
            <a href="https://linkedin.com" className="text-white text-2xl"><FaLinkedin /></a>
            <a href="https://instagram.com" className="text-white text-2xl"><FaInstagram /></a>
          </div>
        </div>
        <div className="text-center mt-6">
          <p className="text-sm">&copy; {new Date().getFullYear()} Reliance Power Supply. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
