import React from 'react';
import HeroSection from './HeroSection'; // Adjust the path as needed
import InfoSection from './InfoSection';
import ProductSection from './ProductSection';
import ToolsSection from './ToolsSection';
import RequestQuoteSection from './RequestAQuoteSection';
import BiddingSection from './BiddingSection';

const Home: React.FC = () => {
  return (
    <div>
      <HeroSection />
      <InfoSection />
      <ProductSection />
      <ToolsSection />
      <BiddingSection />
      <RequestQuoteSection />
      {/* Other components or content for Home */}
    </div>
  );
};

export default Home;
