import React from 'react';

const GeneratorsSection: React.FC = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-extrabold text-gray-900">Generators</h2>
          <p className="mt-2 text-lg text-gray-700">Specifications to choose from:</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-10">
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900">Fuel Type</h3>
            <ul className="mt-2 text-gray-700">
              <li>Natural Gas</li>
              <li>Liquid Propane</li>
              <li>Diesel</li>
            </ul>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900">Kilowatts</h3>
            <ul className="mt-2 text-gray-700">
              <li>&lt; 25kW</li>
              <li>25kW - 100kW</li>
              <li>100kW - 150kW</li>
              <li>150kW+</li>
            </ul>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900">Frequency</h3>
            <ul className="mt-2 text-gray-700">
              <li>60 Hz</li>
              <li>50 Hz</li>
            </ul>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md mb-10">
          <h3 className="text-2xl font-bold text-yellow-600 mb-4">Gaseous Generators</h3>
          <p className="text-gray-700 mb-4">
            Reliance Power Supply's gaseous fuel generators are the perfect solution for businesses looking for uninterrupted power supply without the hassle of frequent refueling. These generators offer extended run times, superior engines that are cost-effective, and eco-friendly emissions. Designed to meet local code requirements, each generator is tailored to meet the unique backup power needs of every business.
          </p>
          <ul className="text-gray-700 grid grid-cols-2 gap-4">
            <li>&lt; 35 kW</li>
            <li>35 kW - 50 kW</li>
            <li>50 kW - 70 kW</li>
            <li>70 kW - 100 kW</li>
            <li>100 kW - 150 kW</li>
            <li>150 kW - 300 kW</li>
            <li>350 kW - 450 kW</li>
            <li>500 kW</li>
            <li>625 kW - 750 kW</li>
            <li>1000 kW</li>
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md mb-10">
          <h3 className="text-2xl font-bold text-yellow-600 mb-4">Diesel Generators</h3>
          <p className="text-gray-700 mb-4">
            The efficient and reliable diesel-fueled generators intended for large backup power applications. Each diesel-powered solution is carefully tailored to meet the specific needs of various applications and adhere to the highest industry standards, ensuring that every company has access to a dependable and effective power source.
          </p>
          <ul className="text-gray-700 grid grid-cols-2 gap-4">
            <li>10 kW - 30 kW</li>
            <li>35 kW - 50 kW</li>
            <li>60 kW - 80 kW</li>
            <li>100 kW - 175 kW</li>
            <li>200 kW - 250 kW</li>
            <li>275 kW - 300 kW</li>
            <li>350 kW - 600 kW</li>
            <li>750 kW - 800 kW</li>
            <li>900 kW - 1000 kW</li>
            <li>1250 kW +</li>
          </ul>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md mb-10">
          <h3 className="text-2xl font-bold text-yellow-600 mb-4">Bi-Fuel Generators</h3>
          <p className="text-gray-700 mb-4">
            Reliance Power Supply offers a reliable solution for backup power needs through their Bi-Fuel generators that combine the endurance of natural gas with the potency of diesel fuel. These generators conform to NEC and NFPA code requirements for on-site fuel storage, guaranteeing uninterrupted supply during a crisis or extreme weather event. Even if natural gas is unavailable, these generators can run solely on diesel fuel. As the only factory-made Bi-Fuel generators compliant with EPA standards, Reliance Power Supply delivers fully equipped solutions with versatile fueling options to accommodate diverse backup power applications.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold text-yellow-600 mb-4">Transfer Switches & Controllers</h3>
          <p className="text-gray-700">
            Reliance Power Supply has the solution for all industrial transfer needs, from the simplest to the most complex. Whether it's light industrial or critical installations, we have a complete line of industrial transfer switches specifically designed to meet each unique application. Our superior silver composite contacts ensure exceptional reliability and extend product life far beyond standard models. Our switches are ISO 9000 certified, meeting all relevant NEC codes, and capable of full load transfers for operation that is critical, legally required or optional. The flexible switching technologies we offer provide customized solutions for any size project and ensure your specific requirements are met efficiently and effectively.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GeneratorsSection;
