import React from 'react';

const ProductSection: React.FC = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white bg-opacity-50 p-6 border border-gray-300 rounded-lg shadow-lg">
          <div className="text-center mb-6">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">Choosing the right product</h2>
          </div>
          <p className="text-base text-gray-700">
            Generators come in different sizes, and one of the most important factors in choosing the right generator is the kilowatt capacity. There are many different types of generators available on the market, each with varying kilowatt ratings. For example, a portable generator usually ranges from 2-8 kilowatts, while a standby generator can range from 5 to 50 kilowatts. Other types of generators include inverter generators, which are generally smaller and quieter, and industrial generators that can produce up to 2,000 kilowatts or more. When determining which type of generator to purchase, it's important to consider your power needs and the size of your home or business, as well as the amount of fuel required to operate the generator and the ease of maintenance. Additionally, the cost of the generator should also be carefully considered, as a higher kilowatt rating often means a higher price tag.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
