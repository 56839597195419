import React from 'react';
import CommercialHeroSection from './CommercialHeroSection';
import GeneratorsSection from './GeneratorsSection';
import TransformersSection from './TransformersSections';
import RequestQuoteSection from '../RequestAQuoteSection';

const Commercial: React.FC = () => {
  return (
    <div>
      <CommercialHeroSection />
      <GeneratorsSection />
      <TransformersSection />
      <RequestQuoteSection />
    </div>
  );
};

export default Commercial;
