import React from 'react';

const BiddingSection: React.FC = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-extrabold text-gray-900">Bidding Information</h2>
        </div>

        <div className="mb-10">
          <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center justify-center">
            Approved Distributors Bidding Portal
            <span className="ml-2 bg-red-500 h-4 w-4 rounded-full animate-blink"></span>
          </h3>
          <p className="text-gray-700">
            New company distributor acceptance for the Reliance Power Supply Portal is currently closed until early 2025.
            Approved vendors please login to the provided portal link received when initially setting up account or call for assistance.
          </p>
        </div>

        <div className="mb-10">
          <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center justify-center">
            Open: Public Bidding Section
            <span className="ml-2 bg-green-500 h-4 w-4 rounded-full animate-blink"></span>
          </h3>
          <p className="text-gray-700 mb-4">
            Only one public bid is open for customers who can provide delivery to the Virgin Islands.
          </p>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-xl font-bold text-gray-900 mb-2">Project Number: BID: RPS-23-77432</h4>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Quantity of 40 transformers needed</li>
              <li>Bid is only for purchase and delivery</li>
              <li>No install required</li>
              <li>Upon bid acceptance, bidder will need to provide logistical breakdown with estimated shipping dates and cargo ship reservation / BOL numbers</li>
              <li>All logistical expenses fall on the bidder providing the quote</li>
              <li>A 3-year warranty is required on all transformers</li>
              <li>Any special install or certifications request to ensure warranty must be included in the bid and will not be accepted afterwards</li>
              <li>All bids due by September 15, 2024 - No exceptions</li>
            </ul>
            <h4 className="text-xl font-bold text-gray-900 mb-2">Specifications:</h4>
            <ul className="list-disc list-inside text-gray-700">
              <li>New 1250 kVA Padmount Transformer</li>
              <li>High Voltage: 12470 Delta, 95 kV BIL</li>
              <li>Low Voltage: 480Y / 277, 30 kV BIL</li>
              <li>Taps: 2 Above, 2 Below @ 2.5%</li>
              <li>HV Bushings: (6) 15kV 200A Wells & Inserts (dead front loop feed)</li>
              <li>LV Bushings: (4) 6-Hole Spades</li>
              <li>Fluid: Mineral Oil</li>
              <li>Frequency: 60 Hz</li>
              <li>Temperature Rise: 65C</li>
              <li>Cooling Class: ONAN</li>
              <li>Conductor: Al / Al</li>
              <li>Fusing: Bayonet w/ CLFs</li>
              <li>Switch: 2 Pos. LBOR</li>
              <li>Tank Material: Mild Steel</li>
              <li>Arresters: None</li>
              <li>Cabinet Depth: Standard</li>
            </ul>
            <h4 className="text-xl font-bold text-gray-900 mt-4 mb-2">Features & Accessories:</h4>
            <ul className="list-disc list-inside text-gray-700">
              <li>Drain & Sample Valve</li>
              <li>Dial-Type Thermometer</li>
              <li>Liquid Level Gauge</li>
              <li>Pressure Vacuum Gauge</li>
              <li>Schrader Valve</li>
              <li>Pressure Relief Valve</li>
              <li>Padmount Green Paint</li>
              <li>UL Listed</li>
              <li>Transformer corrosion resistance and enclosure integrity designed in accordance with IEEE C57.12.28</li>
              <li>Warranty: 3 years</li>
              <li>Lead Time: Shipping starts within 24 weeks after release</li>
              <li>Approvals: 4-6 weeks (if required)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiddingSection;
