import React from 'react';

const InfoSection: React.FC = () => {
  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Our Products</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Generators, Transformers, and EV Charging Benefits
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="relative bg-white bg-opacity-50 p-6 border border-gray-300 rounded-lg shadow-lg">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Generators</h3>
            <p className="mt-4 text-base text-gray-700">
              Generators can convert mechanical energy from gasoline or diesel engines into electrical energy. This energy can then be used to power various electrical appliances. Generators can also convert solar energy, wind energy, and energy from other sources into electrical energy.
            </p>
          </div>
          <div className="relative bg-white bg-opacity-50 p-6 border border-gray-300 rounded-lg shadow-lg">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Transformers</h3>
            <p className="mt-4 text-base text-gray-700">
              Request a quote for pricing and availability on our transformers:
              <ul className="list-disc list-inside mt-2">
                <li>Substation</li>
                <li>Medium voltage Dry-type</li>
                <li>Padmount</li>
                <li>Switchgear</li>
                <li>Low voltage Dry-type</li>
              </ul>
            </p>
          </div>
          <div className="relative bg-white bg-opacity-50 p-6 border border-gray-300 rounded-lg shadow-lg">
            <h3 className="text-lg leading-6 font-medium text-gray-900">EV Charging Benefits</h3>
            <p className="mt-4 text-base text-gray-700">
              By using electricity generated from renewable sources, electric cars are much less harmful to the environment than cars that run on fossil fuels. They produce much fewer emissions of carbon dioxide and other air pollutants. Furthermore, electric cars are quieter, so they reduce noise pollution.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
