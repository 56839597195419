import React from 'react';
import ContactHeroSection from './ContactHeroSection';
import ContactContentSection from './ContactContentSection';

const Contact: React.FC = () => {
  return (
    <div>
      <ContactHeroSection />
      <ContactContentSection />
    </div>
  );
};

export default Contact;
