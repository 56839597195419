import React from 'react';
import RequestQuoteHeroSection from './RequestQuoteHeroSection';
import RequestQuoteSection from '../RequestAQuoteSection';


const RequestQuote: React.FC = () => {
  return (
    <div>
      <RequestQuoteHeroSection />
      <RequestQuoteSection />
    </div>
  );
};

export default RequestQuote;
