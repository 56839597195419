import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  useEffect(() => {
    const btn = document.querySelector("button.mobile-menu-button");
    const menu = document.querySelector(".mobile-menu");

    btn?.addEventListener("click", () => {
      menu?.classList.toggle("hidden");
    });
  }, []);

  return (
    <header className="bg-gray-800 text-white p-4">
      <nav className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-xl font-bold">Reliance Power Supply</Link>
        <div className="hidden md:flex space-x-4">
          <Link to="/" className="hover:text-gray-300">HOME</Link>
          <Link to="/commercial" className="hover:text-gray-300">COMMERCIAL</Link>
          <Link to="/request-quote" className="hover:text-gray-300">REQUEST A QUOTE</Link>
          <Link to="/contact" className="hover:text-gray-300">CONTACT</Link>
        </div>
        <div className="md:hidden flex items-center">
          <button className="outline-none mobile-menu-button">
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </nav>
      <div className="hidden mobile-menu">
        <ul className="space-y-4">
          <li>
            <Link to="/" className="block text-sm px-2 py-4 hover:bg-gray-700">HOME</Link>
          </li>
          <li>
            <Link to="/commercial" className="block text-sm px-2 py-4 hover:bg-gray-700">COMMERCIAL</Link>
          </li>
          <li>
            <Link to="/request-quote" className="block text-sm px-2 py-4 hover:bg-gray-700">REQUEST A QUOTE</Link>
          </li>
          <li>
            <Link to="/contact" className="block text-sm px-2 py-4 hover:bg-gray-700">CONTACT</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
