import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Commercial from './Components/CommercialPage/Commercial';
import RequestQuote from './Components/Quote/RequestQuote';
import Footer from './Components/Footer';
import Contact from './Components/ContactPage/Contact';


const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path="/request-quote" element={<RequestQuote />} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
