import React from 'react';

const RequestQuoteHeroSection: React.FC = () => {
  return (
    <div className="relative bg-blue-600 text-white min-h-screen flex items-center justify-center">
      <div className="absolute inset-0 bg-opacity-50"></div>
      <div className="container mx-auto p-4 flex flex-col items-center text-center">
        <img src="/assets/RPSLogo2.png" alt="Reliance Power Supply Logo" className="mb-4 w-56 h-32" /> {/* Update the path to your logo */}
        <h1 className="text-5xl font-bold mb-4">Request a Quote</h1>
        <p className="text-2xl mb-4">Get a quote for our reliable transformers and generators</p>
        <p className="text-xl mb-2">Email: <a href="mailto:info@reliancepowersupply.com" className="text-blue-200">info@reliancepowersupply.com</a></p>
        <p className="text-xl">Phone: <a href="tel:+1234567890" className="text-blue-200">+1 (234) 567-890</a></p>
      </div>
    </div>
  );
};

export default RequestQuoteHeroSection;
