import React from 'react';

const transformers = [
  {
    title: 'Substation',
    imageUrl: '/assets/substation.jpg',
    description: 'Substation transformers are critical components in power distribution networks. They step down high voltage electricity from power plants to lower voltage levels suitable for residential and commercial use. These transformers are known for their efficiency and reliability in handling large amounts of electricity.',
  },
  {
    title: 'Medium voltage Dry-type',
    imageUrl: '/assets/Medium.jpg',
    description: 'Medium voltage dry-type transformers are used in applications where fire safety is a concern. Unlike oil-filled transformers, dry-type transformers use air or other inert gases for insulation, making them ideal for indoor installations and areas with strict fire codes.',
  },
  {
    title: 'Padmount',
    imageUrl: '/assets/Padmount.jpeg',
    description: 'Padmount transformers are installed at ground level and are commonly used in urban and suburban areas. They are enclosed in steel cases and placed on concrete pads, providing a safe and aesthetically pleasing solution for distributing electricity to residential and commercial buildings.',
  },
  {
    title: 'Switchgear',
    imageUrl: '/assets/switchgear.jpg',
    description: 'Switchgear transformers are essential for controlling, protecting, and isolating electrical equipment in power systems. They ensure the safe and reliable operation of electrical networks by managing the flow of electricity and protecting against overloads and faults.',
  },
  {
    title: 'Low voltage Dry-type',
    imageUrl: '/assets/Low.jpg',
    description: 'Low voltage dry-type transformers are designed for applications that require safe and reliable voltage reduction. They are commonly used in commercial buildings, schools, hospitals, and industrial facilities, providing a safe alternative to oil-filled transformers.',
  },
];

const TransformersSection: React.FC = () => {
  return (
    <div className="bg-gray-200 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-extrabold text-gray-900">Transformers</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {transformers.map((transformer, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
              <img src={transformer.imageUrl} alt={transformer.title} className="mx-auto mb-4 w-56 h-56 object-contain" />
              <h3 className="text-xl font-semibold text-gray-900">{transformer.title}</h3>
              <p className="mt-2 text-gray-700">{transformer.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TransformersSection;
