import React from 'react';

const ContactHeroSection: React.FC = () => {
  return (
    <div className="relative bg-green-600 text-white min-h-screen flex items-center justify-center">
      <div className="absolute inset-0 bg-opacity-50"></div>
      <div className="container mx-auto p-4 flex flex-col items-center text-center">
        <img src="/assets/RPSLogo2.png" alt="Reliance Power Supply Logo" className="mb-4 w-56 h-32" /> {/* Update the path to your logo */}
        <h1 className="text-5xl font-bold mb-4">Contact Us</h1>
        <p className="text-2xl mb-4">We're here to help you with any inquiries or support</p>
        <p className="text-xl mb-2">Email: <a href="mailto:info@reliancepowersupply.com" className="text-green-200">info@reliancepowersupply.com</a></p>
        <p className="text-xl">Phone: <a href="tel:+1234567890" className="text-green-200">(631) 306-4602</a></p>
      </div>
    </div>
  );
};

export default ContactHeroSection;
