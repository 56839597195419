import React from 'react';

const ToolsSection: React.FC = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-100 to-indigo-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-10">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">All the Tools You Need to Succeed</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Commercial and Residential
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="relative bg-white p-6 border border-gray-300 rounded-lg shadow-lg transition transform hover:scale-105">
            <h3 className="text-2xl leading-6 font-bold text-gray-900 mb-4">Commercial</h3>
            <p className="text-base text-gray-700">
              The unique needs of commercial, industrial and life-critical applications. We are committed to delivering high-quality generator options, cutting-edge mobile products, as well as transfer switches and accessories that complement our distinguished product line. Our solutions ensure seamless access to power regardless of location or environment – from indoor offices to remote construction sites. When it comes to powering industrial applications, Reliance Power Supply generators rise above the competition in terms of performance and reliability.
            </p>
          </div>
          <div className="relative bg-white p-6 border border-gray-300 rounded-lg shadow-lg transition transform hover:scale-105">
            <h3 className="text-2xl leading-6 font-bold text-gray-900 mb-4">Residential</h3>
            <p className="text-base text-gray-700">
              For power outages. Our portable generators provide safe and clean energy with a simple switch, ensuring that you can stay comfortable in your home during blackouts. With a range of options available, we can help you select the right generator to back up your most essential lights and appliances. Trust Reliance Power Supply to keep you powered and protected during any outage. Reliance Power Supply offers the perfect solution for power outages. Our portable generators provide safe and clean energy with a simple switch, ensuring that you can stay comfortable in your home during blackouts. With a range of options available, we can help you select the right generator to back up your most essential lights and appliances. Trust Reliance Power Supply to keep you powered and protected during any outage.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsSection;
