import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const CommercialHeroSection: React.FC = () => {
  return (
    <div className="relative bg-gray-700 text-white min-h-screen flex items-center justify-center">
      <div className="absolute inset-0 bg-opacity-50"></div>
      <div className="container mx-auto p-4 flex flex-col items-center text-center">
        <img src="/assets/RPSLogo2.png" alt="Reliance Power Supply Logo" className="mb-4 w-56 h-32" /> {/* Update the path to your logo */}
        <h1 className="text-5xl font-bold mb-4">Commercial Solutions</h1>
        <p className="text-2xl mb-4">Powering Your Business with Reliable Generators and Transformers</p>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-4">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-full">Explore Commercial Products</button>
          <button className="bg-transparent border-2 border-white text-white px-4 py-2 rounded-full">Get a Quote</button>
        </div>
        <div className="flex space-x-6 mb-4 justify-center">
          <a href="https://facebook.com" className="text-white text-2xl"><FaFacebook /></a>
          <a href="https://twitter.com" className="text-white text-2xl"><FaTwitter /></a>
          <a href="https://linkedin.com" className="text-white text-2xl"><FaLinkedin /></a>
          <a href="https://instagram.com" className="text-white text-2xl"><FaInstagram /></a>
        </div>
        <div className="text-center">
          <p className="text-xl mb-2">Email: <a href="mailto:info@reliancepowersupply.com" className="text-blue-400">info@reliancepowersupply.com</a></p>
          <p className="text-xl">Phone: <a href="tel:+1234567890" className="text-blue-400">+1 (234) 567-890</a></p>
        </div>
      </div>
    </div>
  );
};

export default CommercialHeroSection;
